<template>
  <vx-card>
    <vx-form
      v-slot="{ loading }"
      :resource="formResource"
      @submit="handleSubmit"
    >
      <div v-if="!isEdit">
        <vx-checkbox
          v-model="formValue.is_fake"
          name="is_fake"
          label="Is virtual"
        />

        <div v-if="!isVirtual">
          <vx-select
            v-model="formValue.code"
            :rules="rules.code"
            :options="currencyList"
            :reduce="({ value }) => value"
            options-label="label"
            name="code"
            label="Currency"
          />

          <vx-input
            v-model="formValue.exchange_rate"
            :rules="rules.exchange_rate"
            name="exchange_rate"
            label="Exchange rate"
          />

          <vx-input
            v-model="formValue.deviation_limit"
            :rules="rules.deviation_limit"
            name="deviation_limit"
            label="Deviation limit %"
          />
        </div>

        <div v-else>
          <vx-input
            v-model="formValue.name"
            :rules="rules.nameCreate"
            name="name"
            label="Name"
          />

          <vx-input
            v-model="formValue.code"
            :rules="rules.codeVirtual"
            name="code"
            label="Code"
          />

          <vx-image-uploader
            v-model="formValue.file_id"
            :rules="rules.file_id"
            name="file_id"
            label="Currency icon"
          />
        </div>
      </div>

      <div v-if="isEdit">
        <div v-if="isVirtual">
          <vx-input
            v-model="formValue.name"
            :rules="rules.name"
            name="name"
            label="Name"
          />
          <vx-input
            v-model="formValue.code"
            :rules="rules.codeVirtual"
            name="code"
            label="Currency code"
          />

          <vx-image-uploader
            v-model="formValue.file_id"
            :rules="rules.file_id"
            :initial-src="initialCurrencyIcon"
            name="file_id"
            label="Currency icon"
          />
        </div>

        <div v-if="!isVirtual">
          <vx-select
            v-model="formValue.code"
            :options="currencyList"
            :rules="rules.code"
            :reduce="({ value }) => value"
            options-label="label"
            name="code"
            label="Currency"
          />
          <vx-input
            v-model="formValue.exchange_rate"
            :rules="rules.exchange_rate"
            name="exchange_rate"
            label="Exchange rate"
          />
          <vx-input
            v-model="formValue.official_exchange_rate"
            disabled
            name="official_exchange_rate"
            label="Official exchange rate"
          />
          <vx-input
            v-model="formValue.deviation_limit"
            :rules="rules.deviation_limit"
            name="deviation_limit"
            label="Deviation limit"
          />
        </div>

        <vx-select
          v-model="formValue.is_active"
          :options="statuses"
          :reduce="({ value }) => value"
          options-label="label"
          name="status"
          label="Status"
        />

        <vx-date-picker
          v-model="formValue.updated_at"
          name="updated_at"
          label="Updated at"
          type="datetime"
          disabled
        />
      </div>

      <vx-button
        :loading="loading"
        variant="primary"
        type="submit"
      >
        Save
      </vx-button>
    </vx-form>
  </vx-card>
</template>

<script>
import { reactive, computed, watch } from '@vue/composition-api'
import { currencies, digitalOceanPath, passDataToResource } from '@/services/resources'
import { setValuesToForm } from '@/services/form'
import VxCard from '@/components/VxCard'
import { VxForm, VxInput, VxSelect, VxDatePicker, VxCheckbox, VxImageUploader } from '@/components/form'
import { VxButton } from '@/components/buttons'
import currencyCodes from '@/services/utils/currencyCodes.json'
import router, { buildRoute, path } from '@/router'
import { find } from 'lodash'

export default {
  name: 'Currency',
  components: {
    VxCard,
    VxForm,
    VxDatePicker,
    VxInput,
    VxSelect,
    VxCheckbox,
    VxImageUploader,
    VxButton
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const isEdit = !!props.dataResolverResponse

    const statuses = [
      {
        value: true,
        label: 'Active'
      },
      {
        value: false,
        label: 'Inactive'
      }
    ]

    const formValue = reactive({
      id: null,
      file_id: null,
      name: '',
      code: null,
      exchange_rate: null,
      official_exchange_rate: null,
      deviation_limit: null,
      is_main: false,
      is_fake: false,
      is_active: true,
      is_out_of_limit: false,
      sync_with_official: false,
      updated_at: ''
    })

    const rules = {
      name: {
        required: true
      },
      nameCreate: {
        required: true,
        min: 2
      },
      codeVirtual: {
        required: true,
        min: 4,
        max: 10,
        regex: /[A-Z]/i
      },
      code: {
        required: true
      },
      deviation_limit: {
        required: true,
        numeric: true,
        min: 0,
        max: 100
      },
      exchange_rate: {
        decimal: true,
        min: 0,
        max: 9999
      }
    }

    const frontToBackMapper = () => {
      return {
        ...formValue
      }
    }

    const formResource = isEdit
      ? passDataToResource(currencies.update, {
        frontToBackMapper,
        requestParams: {
          urlParams: { id: props.dataResolverResponse.id }
        }
      })
      : passDataToResource(currencies.create, {
        frontToBackMapper
      })

    const isVirtual = computed(() => formValue.is_fake)

    const currencyList = Object.entries(currencyCodes).map(([index, label]) => ({
      label: `${label} (${index})`,
      title: label,
      value: index
    }))

    const initialCurrencyIcon = computed(() => {
      return props.dataResolverResponse?.icon
        ? digitalOceanPath(props.dataResolverResponse.icon?.path)
        : ''
    })

    if (isEdit) setValuesToForm(props.dataResolverResponse, formValue)

    watch(() => formValue.code, (value) => {
      if (!isEdit && !isVirtual.value) {
        formValue.name = find(currencyList, { value })?.title
      }
    })

    const handleSubmit = ([err, res]) => {
      if (err) return

      if (!isEdit) {
        router.push(buildRoute(path.updateCurrency, { params: { id: res.data.id } }))
      }
    }

    return {
      isEdit,
      isVirtual,

      formResource,
      formValue,
      rules,
      handleSubmit,

      currencyList,
      statuses,
      initialCurrencyIcon
    }
  }
}
</script>
