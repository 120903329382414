<template>
  <b-overlay
    class="b-card-loading-wrapper"
    :show="loading || !can"
    rounded
  >
    <template v-if="!can" #overlay>
      <span>No permissions</span>
    </template>

    <b-card
      v-bind="$attrs"
      :class="cardClass"
    >
      <b-card-header
        v-if="$slots.actions || $slots.title"
        class="d-flex align-items-center"
      >
        <h3 class="align-self-center align-self-start mb-0">
          <slot name="title" />
        </h3>

        <slot v-if="can" name="actions" class="align-self-end" />
      </b-card-header>

      <b-card-header v-if="!can && !$slots.title">
        <h3 class="mb-0">
          <wbr>
        </h3>
      </b-card-header>

      <slot v-if="can" />

      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BOverlay } from 'bootstrap-vue'

export default {
  name: 'VxCard',
  components: { BCard, BCardHeader, BOverlay },
  props: {
    loading: Boolean,
    can: {
      type: Boolean,
      default: true
    },
    cardClass: {
      type: [Object, Array, String],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.card::v-deep {
  .card-header,
  .card-footer {
    padding: 1rem 1.5rem;
  }
}
</style>
